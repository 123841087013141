<script lang="ts">
    import { onMount } from "svelte";
    import { quartInOut } from "svelte/easing";

    export let images: {
        ID: number
        Url: string
    }[]
    export let autoSlide = false
    export let autoSlideInterval = 2000

    let wrapperElem: HTMLDivElement
    let carouselElem: HTMLDivElement
    let width: number

    let isAnimating = false;
    let isHovering = false;
    let isAutoSliding = false;

    let direction = 1;
    let index = 0;
    let intervalID: number

    let slideStack: (-1|1)[] = []

    function getNextIndex() {
        return index < images.length - 1 ?
            index + 1 : 0
    }

    function getPrevIndex() {
        return index > 0 ?
            index - 1 : images.length -1
    }

    // Preload images
    images.forEach(image => {
        const imageElem = new Image()
        imageElem.src = image.Url
    })

    function next() {
        slideStack.push(1)

        if (!isAnimating) {
            setNextIndex()
        }

        if (isAutoSliding) {
            stopSliding()
        }
    }

    function prev() {
        slideStack.push(-1)

        if (!isAnimating) {
            setNextIndex()
        }

        if (isAutoSliding) {
            stopSliding()
        }
    }

    function setNextIndex() {
        direction = slideStack.length ? slideStack.shift() : direction

        index = direction > 0 ? getNextIndex() : getPrevIndex()
    }

    function handleResize(e) {
        updateWidth();
    }

    function handleMouseLeave() {
        isHovering = false

        if (autoSlide && !isAutoSliding) {
            startSliding(autoSlideInterval)
        }
    }

    function handleMouseEnter() {
        isHovering = true

        if (autoSlide && isAutoSliding) {
            stopSliding()
        }
    }

    function handleIntroStart() {
        if (!isAutoSliding) {
            isAnimating = true
        }
    }

    function handleIntroEnd() {
        if (!isAnimating) {
            return
        }

        if (slideStack.length) {
            setNextIndex()
        } else {
            isAnimating = false

            if (autoSlide && !isAutoSliding && !isHovering) {
                startSliding(autoSlideInterval)
            }
        }
    }

    function startSliding(interval) {
        direction = 1
        isAutoSliding = true

        intervalID = setInterval(function () {
            setNextIndex()
        }, 5000);
    }

    function stopSliding() {
        isAutoSliding = false
        clearInterval(intervalID);
    }

    function updateWidth() {
        width = wrapperElem.getBoundingClientRect().width;
    }

    onMount(() => {
        updateWidth();

        carouselElem = wrapperElem.closest('.site-banner')

        carouselElem.addEventListener('mouseenter', handleMouseEnter)
        carouselElem.addEventListener('mouseleave', handleMouseLeave)

        if (autoSlide) {
            startSliding(autoSlideInterval)
        }
    });

    function flyOut(
        node,
        { delay = 0, duration = 800, easing: easing$1 = quartInOut, x = 0, y = 0, opacity = 0, }
    ) {
        x = width * direction * -1;

        const style = getComputedStyle(node);
        const target_opacity = +style.opacity;
        const transform = style.transform === "none" ? "" : style.transform;
        const od = target_opacity;

        return {
            delay,
            duration,
            easing: easing$1,
            css: (t, u) => `
                transform: ${transform} translate(${(1 - t) * x}px, ${ (1 - t) * y }px);
                opacity: ${target_opacity}
            `,
        };
    }

    function flyIn(
        node,
        { delay = 0, duration = 800, easing: easing$1 = quartInOut, x = 0, y = 0, opacity = 0, }
    ) {
        x = width * direction;

        const style = getComputedStyle(node);
        const target_opacity = +style.opacity;
        const transform = style.transform === "none" ? "" : style.transform;
        const od = target_opacity;

        return {
            delay,
            duration,
            easing: easing$1,
            css: (t, u) => `
                transform: ${transform} translate(${(1 - t) * x}px, ${ (1 - t) * y}px);
                opacity: ${target_opacity}`,
        };
    }
</script>

<svelte:window on:resize={handleResize} />

<div class="site-banner__images"
       bind:this={wrapperElem}
>

    {#each [images[index]] as image (image.ID)}
        {#if image}
            {#if image.Url}
                <div
                    in:flyIn={{ duration: 1000 }}
                    out:flyOut={{ duration: 1000 }}
                    on:introstart={handleIntroStart}
                    on:introend={handleIntroEnd}
                    class="site-banner__image"
                    style="background-image: url('{image.Url}');"></div>
            {/if}
        {/if}
    {/each}

    {#if images.length > 1}
        <a class="prev" href="#prev" on:click|preventDefault={prev}>
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
            </svg>
        </a>

        <a class="next" href="#next" on:click|preventDefault={next}>
            <svg viewBox="0 0 24 24">
                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
        </a>
    {/if}
</div>
